import React, { FormEvent, useState } from 'react'
import styled from 'styled-components'
import {
  BillingCycle,
  FREE_PLAN_MAX_DEVS,
  PricingCurrency,
  PricingTier,
} from '../pages/pricing'
import theme from '../styles/theme'
import BestDealBadge from './BestDealBadge'
import ToggleSelector from './ToggleSelector'
import Box from './primitives/Box'
import Stack from './primitives/Stack'
import Button from './system/Button'

const MIN_DEVS = 1
const MAX_DEVS = 500
const DEFAULT_DEVS = 10

type PricingTierWithPrice = PricingTier & {
  priceAnnually: number
  priceMonthly: number
}

const PricingCalculator = ({
  tiers,
  currency,
  billingCycle,
  setBillingCycle,
}: {
  tiers: PricingTier[]
  currency: PricingCurrency
  billingCycle: BillingCycle
  setBillingCycle: (billingCycle: BillingCycle) => void
}) => {
  const tiersWithPrice = tiers.filter(tierHasPrice)
  const defaultTier = tiersWithPrice.find(t => t.isHighlighted)!
  const [selectedTier, setSelectedTier] = useState(defaultTier)

  const billingCycleOptions = [
    {
      title: 'Monthly',
      value: BillingCycle.Monthly,
    },
    {
      title: 'Annually',
      value: BillingCycle.Annually,
    },
  ]
  const selectedBillingCycleOption = billingCycleOptions.find(
    option => option.value === billingCycle,
  )!

  const [devCount, setDevCount] = useState(`${DEFAULT_DEVS}`)

  return (
    <Stack textAlign="center">
      <Box.h2 font="h2">Calculate your monthly cost</Box.h2>
      <Box
        marginX="auto"
        marginTop={64}
        gap={48}
        display="flex"
        flexDirection={{ xs: 'column', md: 'row' }}
      >
        <Box>
          <Box marginBottom={20}>Choose a plan</Box>
          <ToggleSelector<PricingTierWithPrice>
            options={tiersWithPrice}
            selectedOption={selectedTier}
            onChange={t => setSelectedTier(t)}
          />
        </Box>
        <Box>
          <Box marginBottom={20}>Choose a billing cycle</Box>
          <ToggleSelector
            options={billingCycleOptions}
            selectedOption={selectedBillingCycleOption}
            onChange={option => setBillingCycle(option.value)}
          />

          <Box display="flex" marginTop={16} lineHeight="120%">
            <Box
              css={`
                margin-left: auto;
                margin-right: 61px;
              `}
            >
              <BestDealBadge active={billingCycle === BillingCycle.Annually} />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box font="medium" marginTop={36} textAlign="left">
        <Box.span fontWeight={700}>{selectedTier.title} plan</Box.span> for{' '}
        <NumberInput value={devCount} onChange={count => setDevCount(count)} />{' '}
        developers
      </Box>
      <Box
        display="flex"
        flexDirection={{ xs: 'column', lg: 'row' }}
        gap={{ xs: 0, lg: 12 }}
        alignItems="center"
        marginTop={{ xs: 48, lg: 0 }}
      >
        <Slider value={devCount} onChange={count => setDevCount(count)} />
        <Stack marginTop={{ xs: 40, lg: 12 }}>
          <Box font="h6" fontSize={40}>
            {formatTotalCost(
              parseDevCount(devCount),
              pickPrice(selectedTier, billingCycle),
              currency,
            )}
          </Box>
          <Box font="textLabel" marginTop={4}>
            {billingCycle === BillingCycle.Annually
              ? 'per month, billed annually'
              : 'per month'}
          </Box>
          <Box
            font="textLabel"
            color="black400"
            marginTop={16}
            width={240}
            minHeight={48}
          >
            {getDevCountCta(parseDevCount(devCount))}
          </Box>
        </Stack>
      </Box>
    </Stack>
  )
}

const tierHasPrice = (tier: PricingTier): tier is PricingTierWithPrice => {
  return (
    typeof tier.priceAnnually === 'number' &&
    tier.priceAnnually > 0 &&
    typeof tier.priceMonthly === 'number' &&
    tier.priceMonthly > 0
  )
}

function pickPrice(
  tier: Pick<PricingTierWithPrice, 'priceAnnually' | 'priceMonthly'>,
  billingCycle: BillingCycle,
) {
  return billingCycle === BillingCycle.Annually
    ? tier.priceAnnually
    : tier.priceMonthly
}

const formatTotalCost = (
  count: number,
  price: number,
  currency: PricingCurrency,
) => {
  const total = count <= FREE_PLAN_MAX_DEVS ? 0 : count * price

  // Setting minimumFractionDigits explicity to 0 fixes old Safari bug
  // See: https://stackoverflow.com/questions/41045270/range-error-with-tolocalestring-with-maximumnumber-of-digits-0
  const minimumFractionDigits = 0
  const maximumFractionDigits = 0
  if (currency === 'EUR') {
    return Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits,
      maximumFractionDigits,
    }).format(total)
  }
  return Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits,
    maximumFractionDigits,
  }).format(total)
}

const NumberInput = ({
  value,
  onChange,
}: {
  value: string
  onChange: (value: string) => void
}) => {
  const handleInput = (event: FormEvent<HTMLInputElement>) => {
    onChange(event.currentTarget.value)
  }
  return (
    <Box.input
      type="number"
      min={MIN_DEVS}
      value={value}
      width={92}
      font="h5"
      textAlign="center"
      borderColor="black200"
      borderRadius={12}
      paddingRight={0}
      paddingLeft={12}
      paddingY={12}
      onChange={handleInput}
    />
  )
}

const RangeInput = styled.input`
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 20px;
  margin: 0;
  background: ${theme.colors.black50};
  border-radius: 10px;

  /* Make a background that colors the slider befor the handle in a different color. */
  background-image: linear-gradient(
    ${theme.colors.purple},
    ${theme.colors.purple}
  );
  background-size: ${({ percentage }: { percentage: number }) => percentage}%
    100%;
  background-repeat: no-repeat;

  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    border: 5px solid ${theme.colors.white};
    background: ${theme.colors.purple};
    cursor: ew-resize;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
  }
  ::-moz-range-thumb {
    appearance: none;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    border: 5px solid ${theme.colors.white};
    background: ${theme.colors.purple};
    cursor: ew-resize;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
  }
  ::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }
`

const Slider = ({
  value,
  onChange,
}: {
  value: string
  onChange: (value: string) => void
}) => {
  const handleInput = (event: FormEvent<HTMLInputElement>) => {
    onChange(event.currentTarget.value)
  }
  const numValue = parseDevCount(value)

  // Ensure bg doesn't overflow behind handle in the right end
  const visualAdjustment = 0.99
  return (
    <Stack width="100%">
      <RangeInput
        type="range"
        min={MIN_DEVS}
        max={MAX_DEVS}
        value={numValue}
        onInput={handleInput}
        percentage={visualAdjustment * (numValue / MAX_DEVS) * 100}
      />
      <Box
        position="relative"
        color="black400"
        font="buttonLabel"
        marginTop={12}
        fontSize={12}
      >
        <Box position="absolute" left={15}>
          {MIN_DEVS}
        </Box>
        <Box
          position="absolute"
          left="50%"
          css={`
            margin-left: -10px;
          `}
        >
          {MAX_DEVS / 2}
        </Box>
        <Box position="absolute" right={8}>
          <Box>{MAX_DEVS}</Box>
        </Box>
      </Box>
    </Stack>
  )
}

const parseDevCount = (value: string): number => {
  const num = parseInt(value, 10)
  if (isNaN(num)) {
    return MIN_DEVS
  }
  return num
}

const getDevCountCta = (devCount: number) => {
  const contactSales = (
    <Button
      href="/demo/"
      variant="basicLink"
      color="black400"
      borderBottomStyle="none"
    >
      Contact sales
    </Button>
  )

  if (devCount > 499) {
    return <>500+ developers? {contactSales} for a quote.</>
  }
  if (devCount > 49) {
    return <>{contactSales} to hear about volume discounts.</>
  }
  if (devCount > FREE_PLAN_MAX_DEVS) {
    return (
      <Button
        href="/signup/"
        variant="basicLink"
        color="black400"
        borderBottomStyle="none"
      >
        Start your free 14-day trial.
      </Button>
    )
  }

  return (
    <>
      <Button
        href="/signup/"
        variant="basicLink"
        color="black400"
        borderBottomStyle="none"
      >
        Sign up
      </Button>{' '}
      for a free account.
    </>
  )
}

export default PricingCalculator
