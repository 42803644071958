import React from 'react'
import FAQWrapper from './FAQWrapper'

const FAQ = () => {
  return (
    <FAQWrapper>
      <details>
        <summary>Do I need a credit card to start a free trial?</summary>
        <p>
          No, you don’t need a credit card to start your free trial. When you’re
          installing Swarmia from the{' '}
          <a href="https://www.swarmia.com/signup/">GitHub Marketplace</a> you
          might see an Order Summary page asking for your payment information.
          If this happens, you can click on the{' '}
          <b>Complete order and begin installation</b> button without entering
          any payment information.
        </p>
      </details>
      <details>
        <summary>
          What are the differences between the Lite and Standard plans?
        </summary>
        <p>
          The Lite plan is a limited version of Swarmia that helps improve the
          pull request flow in teams. The Standard plan, on the other hand,
          offers a more holistic view into the effectiveness of the whole
          engineering organization, including insights on business outcomes,
          developer productivity, and developer experience.
        </p>
      </details>
      <details>
        <summary>What’s included in the free plan?</summary>
        <p>
          The free plan contains all the features and functionality in the
          Standard plan. It’s available for companies with fewer than 10
          software developers.
        </p>
      </details>
      <details>
        <summary>Can I pay monthly?</summary>
        <p>
          Contact your account manager or email us at{' '}
          <a href="mailto:hello@swarmia.com">hello@swarmia.com</a> for more
          information on payment options.
        </p>
      </details>
    </FAQWrapper>
  )
}

export default FAQ
